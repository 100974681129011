import React, { Component } from 'react';
import './Authorize.scss';
import { connect } from 'react-redux';
import { loginRequest } from '../../../containers/Auth/LoginContainer/action';
import * as webConst from '../../../utils/WebConstant';
import { withHeader } from '../../../service/ApiConfig';
import { Translate } from 'react-localize-redux';
import logo from '../../../images/logo.png';
import * as routes from '../../../utils/RouteConstant';

class Authorize extends Component {

  constructor(props) {
    super(props);
    // set the state of the app
    this.state = {
      auth_token: '',
      error: '',
      isLoading: true,
      loggedIn: false,
    }
    // bind component with event handlers
    this.handleSubmit = this.handleSubmit.bind(this);
    window.localStorage.removeItem(webConst.LOGIN_TOKEN);
  }

  // event to handle form submit
  async handleSubmit() {
    let auth_token = "";
    if(this.props && this.props.match && this.props.match.params){
      auth_token=this.props.match.params.token;
    }

    const params = new URLSearchParams();
    params.append('emailid', "");
    params.append('password', "");
    params.append('auth_token', auth_token);
    this.props.loginRequest(params);
    this.setState({ 'isLoading': true });
  }
  
  componentDidMount(){
    setTimeout(() => {
      this.handleSubmit();  
    }, 100);    
  }
  componentDidUpdate(prevProps) {
    const { loginSuccess, loginStatus } = this.props;
    if (loginStatus === 200) {
      if (prevProps.loginSuccess !== this.props.loginSuccess) {
        webConst.setUserToken(loginSuccess);
        setTimeout(() => {
          withHeader.headers.Authorization = webConst.getUserToken();
          this.props.history.push(routes.GOAL_ROUTE+"/"+loginSuccess.goal_client_id);
          // window.location.href=routes.GOAL_ROUTE+"/"+loginSuccess.goal_client_id;
        }, 200);
      }
    }

    if (prevProps.loginFail!==this.props.loginFail) {
      webConst.removeUserToken();
      webConst.emitter.emit(webConst.LOGIN_EVENT);
      webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
      window.location.href=routes.LOGIN_ROUTE;
    }
  }


  render() {
    return (
      
      <div className="vg-login-bg vg-layout-main">

        {this.state.isLoading
          ? <div className="loading"></div>
          :
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className="vg-form-content">
                <div className="text-center">
                  <div className="vg-logo">
                    <img src={logo} width="60" alt="" className="logo" />
                  </div>

                    <div className="vg-header-login pt-4">
                      <h2 className="app-title"><Translate id="app-title"/></h2>
                    </div>
                </div>
            </div>
            </div>
          </div>
        }
      </div>
      
    );
  }
}


function mapStateToProps(state) {
  // console.log(state.login);
  return {
    loginSuccess: state.login.success,
    loginFail: state.login.fail,
    loginStatus: state.login.status
  };
}

const mapDispatchToProps = { loginRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Authorize);