import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import * as routes from '../../../utils/RouteConstant';
import * as webConst from '../../../utils/WebConstant';
import { logoutRequest } from '../../../containers/Auth/LogoutContainer/action';


class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.logoutRequest();
  }

  componentDidUpdate(prevProps) {

    const { logoutSuccess, logoutFail, logoutStatus } = this.props;

    if (logoutStatus === 200) {
      if (prevProps.logoutSuccess !== logoutSuccess) {
        this.setState({
          isLoading: false
        });
        webConst.removeUserToken();
        webConst.emitter.emit(webConst.LOGIN_EVENT);
        this.forceUpdate();
        console.log("success");
      }
    }

    if (logoutFail) {
      if (prevProps.logoutFail !== logoutFail) {
        console.log("fail");
        this.setState({ 'isLoading': false });
        webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
      }
    }

  }

  render() {
    console.log("logout");
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN_ROUTE,
          state: {}
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    logoutSuccess: state.logout.success,
    logoutFail: state.logout.fail,
    logoutStatus:state.logout.status
  };
}

const mapDispatchToProps = {logoutRequest};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
