import { GOAL_SUCCESS, GOAL_FAIL,JOURNAL_SUCCESS,JOURNAL_FAIL } from './Constants';

const initialState = {
  isLoading: true
};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case GOAL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status
      };
    case GOAL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status
      };
    case JOURNAL_SUCCESS:
      return {
        ...state,
        journalSuccess: action.success,
        journlFail: '',
        journalStatus: action.status
      };
    case JOURNAL_FAIL:
      return {
        ...state,
        journalSuccess: '',
        journalFail: action.error,
        journalStatus: action.status
      };
    default:
      return state;
  }
}