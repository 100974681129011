import React from 'react';
import { toast } from 'react-toastify';
import * as routes from '../utils/RouteConstant';
import { Redirect } from "react-router-dom";
//export const WEB_SERVICE_URL='https://dev17.validi.dk/validi-goal-api';
export const WEB_SERVICE_URL = process.env.REACT_APP_WEB_SERVICE_URL;
// export const WEB_SERVICE_URL = "http://localhost/validi/validi-goal-api";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN = '/login/index.php';
export const CLIENT_LIST = '/get_client_list/index.php';
export const GET_GOAL_TOPICS = '/goal_topic/index.php';
export const GET_GOALS = '/get_goals/index.php';
export const CREATE_GOAL = '/create_goal/index.php';
export const COMPLETE_GOAL = '/complete-goal/index.php';
export const GET_GOAL_JOURNAL = '/get-goal-journals/index.php';
export const ADD_GOAL_JOURNAL = '/add-goal-journal/index.php';
export const GET_COMPLETED_GOAL='/get-completed-goals/index.php';
export const LOGOUT = '/logout/index.php';

// Toater here 
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';
export const LOGIN_USER = 'validi-user';
export const LOGIN_TOKEN = 'validi-user-token';
export const LOGIN_TIME = 'validi-login-time';
export const CLINIC_ID = 'clinic_id';


export const toaster = (toastType, message) => {
    if (toastType === TOAST_SUCCESS) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    } else if (toastType === TOAST_ERROR) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    }
};


// Event Emitter 
var { EventEmitter } = require('fbemitter');
export const emitter = new EventEmitter();

export const LOGIN_EVENT = 'login-event';

// Routes 
export const REDICT_ROIUTE = '/';

//set token
export const setUserToken = data => {
    let token = data && typeof data.ut !== 'undefined' ? data.ut : '';
    let currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);
    window.localStorage.setItem(LOGIN_TIME, currentTime.valueOf());
    window.localStorage.setItem(LOGIN_USER, JSON.stringify(data));
    window.localStorage.setItem(CLINIC_ID, data.staff_clinic_id);
    return token !== undefined && token !== "" ? window.localStorage.setItem(LOGIN_TOKEN, token) : '';
};


//check auth
export const checkAuth = () => {
    var data = window.localStorage.getItem(LOGIN_TOKEN);
    data = data !== '' ? JSON.parse(data) : "";
    var token = data && typeof data.ut !== 'undefined' ? true : false;
    return token;
}

// get user token
export const getUserToken = () => {
    var data = window.localStorage.getItem(LOGIN_TOKEN);
    var token = data && typeof data !== 'undefined' ? data : '';
    return token;
};

// remove token
export const removeUserToken = () => {
    window.localStorage.removeItem(LOGIN_TOKEN);
    window.localStorage.removeItem(LOGIN_USER);
    window.localStorage.removeItem(LOGIN_TIME);
    window.localStorage.removeItem(CLINIC_ID);
    emitter.emit(LOGIN_EVENT);
    return <Redirect to='/login' />
};

export const getUserId = () => {
    var data = window.localStorage.getItem(LOGIN_USER);
    data = data !== '' ? JSON.parse(data) : "";
    return data && typeof data !== 'undefined' && typeof data.user_id !== 'undefined' ? data.user_id : 0;
};

export const getClinicId = () => {
    return window.localStorage.getItem(CLINIC_ID);
}
// get user
export const getUser = () => {
    var data = window.localStorage.getItem(LOGIN_USER);
    data = data !== '' ? JSON.parse(data) : "";
    return data && typeof data != undefined ? data : '';
};

//get last login time
export const getLoginTime = () => {
    var time = window.localStorage.getItem(LOGIN_TIME);
    return typeof time != undefined ? time : '';
}

// auto logout here 
export const autoLogOut = (props) => {
    props.history.push(routes.LOGIN_ROUTE);
};

// get route 
export const getUserRoute = () => {
    return routes.HOME_ROUTE;
};

// remove route 
export const removeUserRoute = () => {
    return window.localStorage.removeItem(REDICT_ROIUTE);
};

export const loader = (props) => {
    if (props)
        return <img src={BASE_URL + '/images/loading.gif'} alt="wait" />
    else
        return '';
}

export const ComponentToPrint=(props)=>{
   return <div>My cool content here!</div>
}
