import React, { Component } from 'react';
import './Login.scss';
import { connect } from 'react-redux';
import { loginRequest } from '../../../containers/Auth/LoginContainer/action';
import * as webConst from '../../../utils/WebConstant';
import { withHeader } from '../../../service/ApiConfig';
import { Translate } from 'react-localize-redux';
import SimpleReactValidator from 'simple-react-validator';
import logo from '../../../images/logo.png';

class Login extends Component {

  constructor(props) {
    super(props);

    // set the state of the app
    this.state = {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      error: '',
      isLoading: false
    }

    // bind component with event handlers
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    this.validator = new SimpleReactValidator({
      element: message => <div className="text-danger">{message}</div>,
      messages: {
        email: 'Please enter valid email address.',
        default: 'This field is required.'
      }
    });
  }

  // event to handle input change
  handleChange = (name, value) => {
    this.setState({ 'error': '' });
    this.setState({ form: { ...this.state.form, [name]: value } });
  }

  // event to handle form submit
  async handleSubmit(event) {
    event.preventDefault();
    if (this.validator.allValid()) {
      const { form } = this.state
      const params = new URLSearchParams();
      params.append('emailid', form.email);
      params.append('password', form.password);
      this.props.loginRequest(params);
      this.setState({ 'isLoading': true });
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  showError(fieldname) {
    return this.state.formError && this.state.formError && this.state.formError[fieldname] ? <span className="text-danger">{this.state.formError[fieldname]}</span> : '';
  }

  componentDidUpdate(prevProps) {
    const { loginSuccess, loginFail, loginStatus } = this.props;
    if (loginStatus === 200) {
      if (prevProps.loginSuccess !== this.props.loginSuccess) {
        // console.log("loginStatus", this.props);
        this.setState({
          isLoading: false,
          formError: '',
          logSuccess: loginSuccess
        }, () => {
          this.afterSuccessLogin(loginSuccess);
        });
      }
    }

    if (loginFail) {
      if (prevProps.loginFail !== loginFail) {
        this.setState({
          isLoading: false,
          logSuccess: '',
          formError: loginFail,
        }, () => {
          webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
        });

      }
    }
  }

  afterSuccessLogin(objData) {
    webConst.setUserToken(objData);
    webConst.emitter.emit(webConst.LOGIN_EVENT);
    withHeader.headers.Authorization = webConst.getUserToken();
    if (webConst.getUserRoute()) {
      this.props.history.push('/home');
    } else {
      this.props.history.push('/home');
    }
  }


  render() {
    return (
      
      <div className="vg-login-bg vg-layout-main">

        {this.state.isLoading
          ? <div className="loading"></div>
          :
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className="vg-form-content">
                <div className="text-center">
                  <div className="vg-logo">
                    <img src={logo} width="60" alt="" className="logo" />
                  </div>

                    <div className="vg-header-login pt-4">
                      <h2 className="app-title"><Translate id="app-title"/></h2>
                    </div>
                    <div className="mt-1 mb-4"><h4><span className="font-weight-bold"><Translate id="login.title" /></span></h4></div>
                </div>

                <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {/* <label><Translate id="login.username" /><span className="text-danger">*</span></label> */}
                  <input type="text" className="form-control" name="email" id="email" onChange={e => this.handleChange(e.target.name, e.target.value)} />
                  {this.validator.message('email', this.state.form.email, 'required')}
                  {this.showError('email')}
                </div>
                <div className="form-group">
                  {/* <label><Translate id="login.password" /><span className="text-danger">*</span></label> */}
                  <input type="password" className="form-control" name="password" id="password" onChange={e => this.handleChange(e.target.name, e.target.value)} />
                  {this.validator.message('password', this.state.form.password, 'required')}
                  {this.showError('password')}
                </div>
                <div className="form-group-btn">
                  <button type="submit" className="btn btn-block btn-outline-primary vg-btn" name="submit" id="submit"><Translate id="login.title" /></button>
                </div>
                {this.state.isLoading ? <img src="images/loading.gif" alt="wait" /> : ''}
                <span className="text-danger">{this.state.error}</span>
              </form>
            </div>
            </div>
          </div>
        }
      </div>
      
    );
  }
}


function mapStateToProps(state) {
  // console.log(state.login);
  return {
    loginSuccess: state.login.success,
    loginFail: state.login.fail,
    loginStatus: state.login.status,
    loggedIn: state.login.loggedIn
  };
}

const mapDispatchToProps = { loginRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Login);