import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { getCompletedGoalRequest } from '../../containers/CompletedGoalContainer/action';
import * as webConst from '../../utils/WebConstant';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import * as routes from '../../utils/RouteConstant';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import './GoalDetail.scss';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    // console.log('totally custom!',eventKey)
  });
  return (
    <button
      type="button"
      className={"btn btn-sm btn-primary"}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

class GoalDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'isLogin': props.loginStatus ? props.loginStatus : false,
      'goal': [],
      'goalId': typeof this.props.match.params.goal_id != undefined ? this.props.match.params.goal_id : 0,
      isLoading: true
    };
  }

  componentDidMount() {
    const params = new URLSearchParams();
    if (this.props.match && this.props.match.params && this.props.match.params.goal_id)
      params.append('goal_id', this.props.match.params.goal_id);
    params.append('token', webConst.getUserToken());
    this.setState({ 'defaultActiveKey': this.props.match.params.goal_id });
    this.props.getCompletedGoalRequest(params);
  }

  componentDidUpdate(prevProps) {

    const { goalSuccess, goalFail, goalStatus } = this.props;

    if (goalStatus === 200) {
      if (prevProps.goalSuccess !== goalSuccess) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false,
          });
        }

        this.setState({ 'goal': goalSuccess.client_goals });

      }
    }
    if (prevProps.goalFail !== goalFail) {
      this.setState({
        isLoading: false,
      });
    }

  }



  createMainGoalJournal(item) {
    if (item.journals) {
      return item.journals.map((journalItem, journalKey) => {
        return <li className="list-group-item text-justify" key={journalKey}>
          <div dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 text-left"><span className="fa fa-user-md"></span> {journalItem.username}</div>
            <div className="col-xs-12 col-sm-12 col-md-6 text-right"><span className="fa fa-clock-o"></span> {journalItem.journal_datetime}</div>
          </div>
        </li>
      });
    }
  }

  createPartGoalJournal(partItem) {
    if (partItem.journals) {
      return partItem.journals.map((journalItem, journalKey) => {
        return <li className="list-group-item text-justify" key={journalKey}>
          <div dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div>
          <div className="row">
            <div className="col-md-6 text-left"><span className="fa fa-user-md"></span> {journalItem.username}</div>
            <div className="col-md-6 text-right"><span className="fa fa-clock-o"></span> {journalItem.journal_datetime}</div>
          </div>
        </li>
      });
    }
  }

  createPartGoal(partItem) {
    let partGoalJournals = this.createPartGoalJournal(partItem);
    return <Card key={partItem.id} className="part-goal-bg mt-4 mb-4">
      <Card.Header className="">
        <div className="row">
          <div className="col-md-1 col-sm-1">
            <div className="vg-togglemain">
              <CustomToggle eventKey={partItem.id}><span className="fa fa-angle-double-down"></span></CustomToggle>
            </div>
          </div>
          <div className="col-md-10 col-sm-11">
            <div>
              <div className="text"><strong><Translate id="goal-detail.part-goal-name" /> : </strong> {partItem.name}</div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={partItem.id}>
        <Card.Body className="text-capitalize">

          <div className="row">
            <div className="col-md-12">
              {partItem.journals.length ?
                <div className="card mt-2 card-journal">
                  <div className="card-header vg-span-bg-header">
                    <div className="vg-card-title">
                      <Translate id="client-goal.journals" /></div>
                  </div>

                  <div className="card-body fs-12">
                    <ul className="list-group">{partGoalJournals}</ul>
                  </div>
                </div>
                : <div className="alert alert-danger">
                  <Translate id="goal-detail.no-journals-message" />
                </div>
              }
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  }


  createClientGoal(item) {
    let goalJournals = this.createMainGoalJournal(item);

    let partGoal = [];

    if (item.part_goals && Object.values(item.part_goals).length) {
      // start part goal card
      partGoal = item.part_goals.map((item, itemKey) => {
        return this.createPartGoal(item);
      });
      // end part goal card
    }

    return <div className="row p-3">
      <div className="col-md-12">
        <div className="text-success"><strong><Translate id="client-goal.topic-name" /> : </strong>{item.topic_name}</div>
        <div className="text-primary"><strong><Translate id="client-goal.goal-name" /> : </strong>{item.name}</div>

        {item.journals && Object(item.journals).length ?
          <div className="card mt-2 card-journal">
            <div className="card-header vg-span-bg-header">
              <div className="vg-card-title">
                <Translate id="client-goal.journals" /></div>
            </div>
            <div className="card-body fs-12">
              <ul className="list-group">{goalJournals}</ul>
            </div>
          </div>
          :
          <div className="alert alert-danger">
            <Translate id="goal-detail.no-journals-message" />
          </div>
        }
      </div>
      <div className="col-md-12">
        <Accordion>
          {partGoal}
        </Accordion>
      </div>
    </div>
  }

  render() {
    let clientGoals = [];
    if (this.state.goal && Object.keys(this.state.goal).length) {
      clientGoals = this.createClientGoal(this.state.goal);
    }

    return (
      <div className="vg-card-header">
        <div className="card">
          <div className="card-header vg-main-header">
            <div className="row">
              <div className="col-md-6">
                <div className="vg-card-title">
                  <Translate id="goal-detail.title" />
                </div>
              </div>
              <div className="col-md-6 text-right">
                <Link className="btn btn-primary btn-sm vg-btn-tile" to={routes.GOAL_ROUTE + '/' + this.state.goal.client_id}><Translate id="back" /></Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {this.state.isLoading ?
                <div className="card-body height-200"><div className="loading"></div></div> :
                <Accordion defaultActiveKey={this.state.defaultActiveKey}>
                  {clientGoals}
                </Accordion>
              }
            </div>
          </div>

        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    goalSuccess: state.completedGoal.success,
    goalFail: state.completedGoal.fail,
    goalStatus: state.completedGoal.status
  };
}

const mapDispatchToProps = {
  getCompletedGoalRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetail);
