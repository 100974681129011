import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Translate } from 'react-localize-redux';
import { getClientListRequest } from '../../containers/HomeContainer/action';
import * as routes from '../../utils/RouteConstant';
import * as webConst from '../../utils/WebConstant';
import { Link } from "react-router-dom";
import { Translate } from 'react-localize-redux';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'isLoading': true,
      'clientType': 'mine',
      'clinics': [],
      'defaultClinic': webConst.getClinicId()
    };
    this.getClient = this.getClient.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams();
    params.append('user_id', webConst.getUserId());
    params.append('clinic_id', webConst.getClinicId());
    params.append('token', webConst.getUserToken());
    params.append('type', 'mine');
    this.props.getClientListRequest(params);
  }
  componentDidUpdate(prevProps) {
    const { homeSuccess, homeFail, homeStatus } = this.props;
    if (homeStatus === 200) {
      if (prevProps.homeSuccess !== homeSuccess) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false,
            clinics: homeSuccess.clinics,
            defaultClinic: homeSuccess.defaultClinic
          });
        }
      }
    }
    if (prevProps.homeFail !== homeFail) {
      if (this.state.isLoading) {
        this.setState({
          isLoading: false,
        });
      }
    }
  }


  async getClient(event) {

    let clientType = "mine";
    let defaultClinic="";
    if (event.target.name==="clientType" && event.target.value !== "") {
      clientType = event.target.value;
      this.setState({clientType:clientType});
    }
    else{
      clientType=this.state.clientType;
    }
    if(event.target.name==="filterClinicId"){
      this.setState({defaultClinic:event.target.value});
      defaultClinic=event.target.value;
    }else{
      defaultClinic=webConst.getClinicId();
    }
    this.setState({ 'isLoading': true });
    this.setState({ 'clientType': clientType });
    const params = new URLSearchParams();
    params.append('user_id', webConst.getUserId());
    params.append('type', clientType);
    params.append('clinic_id', defaultClinic);
    params.append('token', webConst.getUserToken());
    this.props.getClientListRequest(params);
  }

  render() {
    const { homeSuccess } = this.props;
    let clientList;
    let defaultClinic="";
    if (homeSuccess) {
      if (homeSuccess.client_list) {
        let clients = homeSuccess.client_list;
        clientList = clients.map((item, key) => {
          return <tr key={key}>
            <td className="text-capitalize"><Link to={routes.GOAL_ROUTE + '/' + item.ID}>{item.client_name}</Link></td>
          </tr>
        });
        defaultClinic=homeSuccess.default_clinic;
        if(this.state.defaultClinic!==this.state.defaultClinic)
          this.setState({"defaultClinic":homeSuccess.default_clinic});
      }
    }
    return (
      <div className="vg-card-header">
        <div className="fh-main-client">
          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header vg-main-header">
                  <div className="vg-card-title">
                    <div className="row">
                      <div className="col-md-4">
                        <Translate id="home.title" />
                      </div>
                      <div className="col-md-4">
                        <div className="vg-form-main">
                          <input type="radio" name="clientType" value="mine" onChange={this.getClient} checked={this.state.clientType === 'mine' ? true : false} id="mine" />
                          <label htmlFor="mine" className="form-label pl-2 pr-2 mb-0"><strong><Translate id="home.mine" /></strong></label>
                          <input type="radio" name="clientType" value="all" onChange={this.getClient} checked={this.state.clientType === 'mine' ? false : true} id="all" />
                          <label htmlFor="all" className="form-label pl-2 pr-2 mb-0"><strong><Translate id="home.all" /></strong></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {this.state.clientType === 'all' ?
                    <div className="row">
                      <select name="filterClinicId"  onChange={this.getClient} className='form-control col-md-6 m-3' defaultValue={defaultClinic}>
                        <Translate>
                          {({ translate }) => <option value="">{translate("home.select-clinic")}</option>}
                        </Translate>
                        {
                          this.state.clinics.map((item, key) => {
                            return <option key={"clinic-"+item.ID} value={item.ID}>{item.display_name}</option>
                          })
                        }
                      </select>
                    </div>
                    : ""
                  }
                  {this.state.isLoading ?
                    <div className="loading"></div> :
                    <table className="table"><thead>{clientList}</thead></table>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeSuccess: state.home.success,
    homeFail: state.home.fail,
    homeStatus: state.home.status
  };
}

const mapDispatchToProps = { getClientListRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
