import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './Footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="vg-footer">    
      <div className="footer-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="vg-card-title text-center">
                    <Translate id="copyright" /> © {new Date().getFullYear()} - <Translate id="app-title" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);