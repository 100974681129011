import { AUTH_STATUS } from './constants';
import * as webConst from '../../utils/WebConstant';


/*
    API REQUEST 
 */
export const getAuthStatus = () => {
    return dispatch => {
        let token = webConst.getUserToken();
        if (token.trim() !== "")
            dispatch(seAuthStatus(true));
        else
            dispatch(seAuthStatus(false));
    }
};


export function seAuthStatus(loggedIn) {
    return {
        type: AUTH_STATUS,
        loggedIn
    };
}