import { LOGOUT_SUCCESS, LOGOUT_FAIL } from './constants';
import * as webConst from '../../../utils/WebConstant';
import { withHeader } from '../../../service/ApiConfig';
import axios from 'axios';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

/*
    API REQUEST 
 */

export const logoutRequest = () => {
    return async dispatch => {
        const params = new URLSearchParams();
        params.append('token', webConst.getUserToken());
        axios.post(`${webConst.LOGOUT}`, params)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    webConst.emitter.emit(webConst.LOGIN_EVENT);
                    webConst.removeUserToken();
                    dispatch(logoutSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(logoutFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(logoutFailResult(error.data, error.status));
            });
    }

}

/*
    API RESULT
 */

export const logoutSuccessResult = (success, status) => ({
    type: LOGOUT_SUCCESS,
    success,
    status
});


export const logoutFailResult = (error, status) => ({
    type: LOGOUT_FAIL,
    error,
    status
});
