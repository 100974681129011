/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import appLayoutReducer from '../containers/AppLayoutContainer/reducer';
import homeReducer from '../containers/HomeContainer/reducer';
import goalReducer from '../containers/GoalContainer/reducer';

import loginReducer from '../containers/Auth/LoginContainer/reducer';
import logoutReducer from '../containers/Auth/LogoutContainer/reducer';

import completedGoalReducer from '../containers/CompletedGoalContainer/reducer';

const createReducer = combineReducers({
    appLayout:appLayoutReducer,
    login:loginReducer,
    logout:logoutReducer,
    home:homeReducer,
    localize: localizeReducer,
    goal:goalReducer,
    completedGoal:completedGoalReducer
});

export default createReducer;
