import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import logo from '../../../images/logo.png';
import './Header.scss';
import Login from '../../Auth/Login';
import Home from '../../Home';
import Goal from '../../Goal';
import Logout from '../../Auth/Logout';
import GoalDetail from '../../GoalDetail';
import Authorize from '../../Auth/Authorize';
import * as routes from '../../../utils/RouteConstant';
import * as webConst from '../../../utils/WebConstant';
import { Navbar, Nav } from 'react-bootstrap';

const BeforeLoginRoute = ({ component: Component, authed, ...rest }) => (
  <Route
    {...rest}
    render={(props) => authed === false
      ? <Component {...props} />
      : <Redirect to={{ pathname: routes.HOME_ROUTE }} />
    }
  />
);

export function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: routes.LOGIN_ROUTE, state: { from: props.location } }} />}
    />
  )
}


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: webConst.getUserToken() ? true : false,
      user: webConst.getUser() ? webConst.getUser() : {}
    };

    let _this = this;
    webConst.emitter.addListener(webConst.LOGIN_EVENT, () => {
      _this.setState({
        loggedIn: webConst.getUserToken() ? true : false,
        user: webConst.getUser() ? webConst.getUser() : {}
      });
    });
  }

  componentDidUpdate = prevProps => {

    const { loggedIn } = this.props

    if (loggedIn) {
      if (prevProps.loggedIn !== this.props.loggedIn) {
        this.setState({ 'loggedIn': this.props.loggedIn });
      }
    }
  }

  render() {
    console.log("this.state.loggedIn", this.state.loggedIn);
    return (
      <Router>
        {this.state.loggedIn ?
          <Navbar className="navbar shadow-sm vg-navbar" expand="lg">
            <div className="container">
              <Navbar.Brand href={routes.HOME_ROUTE} className="navbar-brand">
                <img src={logo} width="45" alt="" className="d-inline-block align-middle mr-2 logo" />
                <span className="app-title font-weight-bold"> <Translate id="app-title"/></span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav" className="navbar-nav ml-auto">
                {this.state.loggedIn ?
                  <div className="navbar-nav ml-auto">
                    <Nav className="mr-auto">
                      <Nav.Link href={routes.HOME_ROUTE}><Translate id="menu.home" /></Nav.Link>
                      <Nav.Link href={routes.LOGOUT_ROUTE}><Translate id="menu.logout" /></Nav.Link>
                    </Nav>
                  </div>
                  :
                  <div className="navbar-nav ml-auto">
                    <Nav className="mr-auto">
                      <Nav.Link href={routes.LOGIN_ROUTE}><Translate id="menu.login" /></Nav.Link>
                    </Nav>
                  </div>
                }
              </Navbar.Collapse>
            </div>

          </Navbar>
          :
          ''
        }

        <div className="container mt-4">
          <Switch>
            <BeforeLoginRoute authed={false} path={routes.AUTHORIZE_ROUTE+"/:token"} component={Authorize} />
            <PrivateRoute authed={this.state.loggedIn} path="/" exact component={Home} />
            <BeforeLoginRoute authed={this.state.loggedIn} path={routes.LOGIN_ROUTE} component={Login} />
            <PrivateRoute authed={this.state.loggedIn} path={routes.HOME_ROUTE} component={Home} />
            <PrivateRoute authed={this.state.loggedIn} path={routes.GOAL_ROUTE + '/:client_id?'} component={Goal} />
            <Route authed={this.state.loggedIn} path={routes.GOAL_DETAIL_ROUTE + '/:goal_id?'} component={GoalDetail} />
            <PrivateRoute authed={this.state.loggedIn} path={routes.LOGOUT_ROUTE} component={Logout} />
          </Switch>
        </div>
      </Router >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLogin: state.login.loginStatus,
    loggedIn: state.appLayout.loggedIn
  };
}
const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);