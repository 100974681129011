import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Card from 'react-bootstrap/Card';


class PrintGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: props.goals,
      goalTitle: props.goalTitle
    };
    this.initialState = this.state;
  }


  createMainGoalJournal(item) {
    return item.journals.map((journalItem, journalKey) => {
      return <li className="list-group-item" key={journalKey}>
        <div style={{textAlign:"justify"}}><div dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div></div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="glyphicon glyphicon-user"></span> <strong>{journalItem.username}</strong> , {journalItem.clinic}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="glyphicon glyphicon-hourglass"></span> {item.name}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-right"><span className="glyphicon glyphicon-time"></span> {journalItem.journal_datetime}</div>
        </div>
      </li>
    });
  }

  createPartGoalJournal(partItem) {
    return partItem.journals.map((journalItem, journalKey) => {
      return <li className="list-group-item" key={journalKey}>
        <div style={{textAlign:"justify"}} dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-user-md"></span> <strong>{journalItem.username}</strong> , {journalItem.clinic} </div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-hourglass-half"></span> {partItem.name}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-right"><span className="fa fa-clock-o"></span> {journalItem.journal_datetime}</div>
        </div>
      </li>
    });
  }

  createPartGoal(item, partItem) {
    let partGoalJournals = this.createPartGoalJournal(partItem);
    return <Card key={partItem.id} style={{"border":"2px solid #ccc","borderTop":"2px solid #ccc","borderRight":"2px solid #ccc"}} className="mt-4 mb-4">
      <Card.Header>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <Translate id="goal-detail.part-goal-name" />
          </div>
          <div className="col-md-6 col-sm-6">
            {this.state.goalTitle[partItem.id] ? this.state.goalTitle[partItem.id] : ''}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="text-capitalize">
        <div className="row">
          <div className="col-md-12">
            <div className="text-success"><strong><strong><Translate id="client-goal.topic-name" /> : </strong> </strong>{partItem.topic_name}</div>
            <div className="text-primary"><strong><strong><Translate id="client-goal.goal-name" /> : </strong> </strong>{partItem.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {partItem.journals.length ?
              <div className="card mt-4 card-journal">
                <div className="card-header vg-span-bg-header">
                  <div className="vg-card-title">
                    <Translate id="client-goal.journals" /></div>
                </div>
                <div className="card-body fs-12">
                  <ul className="list-group">{partGoalJournals}</ul>
                </div>
              </div>
              : ''
            }
          </div>
        </div>
      </Card.Body>
    </Card>
  }

  createGoal = function (item) {
    let goalJournals = this.createMainGoalJournal(item);

    // start main goal card
    return <Card key={item.id} style={{"border":"2px solid #666","borderTop":"2px solid #666","borderRight":"2px solid #000",marginTop:"5px",marginBottom:"5px"}}>
      <Card.Header style={{"borderBottom":"2px solid #666"}}>
        <div className="row">
          <div className="col-md-10 col-sm-11">
            <strong className="text-capitalize">{item.name}</strong>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="text-success"><strong><Translate id="client-goal.topic-name" /> : </strong>{item.topic_name}</div>
            <div className="text-primary"><strong><Translate id="client-goal.goal-name" /> : </strong>{item.name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {item.journals.length ?
              <div className="card mt-4 card-journal">
                <div className="card-header vg-span-bg-header vg-part-goal-bg-header">
                  <div className="vg-card-title">
                    <Translate id="client-goal.journals" /></div>
                </div>
                <div className="card-body fs-12">
                  <ul className="list-group">{goalJournals}</ul>
                </div>
              </div>
              : ''
            }
            {/* start part goal group */}
            <div>
              {
                item.part_goals.map((partItem, partKey) => {
                  return this.createPartGoal(item, partItem);
                })
              }
            </div>
            {/* end part goal group */}
          </div>
        </div>
      </Card.Body>
    </Card>
  }

  render() {
    const { goals } = this.props;
    let _this = this;
    let clientGoals = [];
    if (goals && goals.length) {
      clientGoals = goals.map((item, key) => {
        return _this.createGoal(item);
      });
    }
    return (
      <div>
        {/* <link href="//cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous"/> */}
        <div>{clientGoals}</div>
      </div>
    );
  }
}

PrintGoal.propTypes = {
  goals: PropTypes.array
};

PrintGoal.defaultProps = {
  goals: []
};

export default PrintGoal;
