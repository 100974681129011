import React, { Component } from 'react';
import './Goal.scss';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { getGoalListRequest, saveGoalRequest, completeGoalRequest, getGoalJournalRequest, addJournalRequest } from '../../containers/GoalContainer/action';
import * as routes from '../../utils/RouteConstant';
import * as webConst from '../../utils/WebConstant';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import PrintGoal from '../../utils/PrintGoal';
import printJS from 'print-js'

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    // console.log('totally custom!',eventKey)
  });
  return (
    <button
      type="button"
      className={"btn btn-sm btn-primary"}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

class Goal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'isLogin': props.loginStatus ? props.loginStatus : false,
      'topics': [],
      'goals': [],
      'goalTitle': [],
      'createGoal': false,
      'topicId': 0,
      'clientId': typeof this.props.match.params.client_id != undefined ? this.props.match.params.client_id : 0,
      'goalName': '',
      'expanJournalGoalId': 0,
      'journal': '',
      'isPartGoalJournal': false,
      'isLoading': true,
      'defaultActiveKey': 0,
      'defaultChildActiveKey': 0,
      'isExpandGoal': false,
      'isExpandPartGoal': false,
      'completedGoals': [],
      'isShowAllAccodian':false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTopic = this.handleTopic.bind(this);
    this.handleCompleteGoal = this.handleCompleteGoal.bind(this);
    this.handleExplandJournal = this.handleExplandJournal.bind(this);
    this.handleSubmitjournal = this.handleSubmitjournal.bind(this);
    this.handleShowAllJournal = this.handleShowAllJournal.bind(this);

    this.journalValidator = new SimpleReactValidator({
      element: message => <div className="text-danger">{message}</div>,
      messages: {
        default: 'This field is required.'
      }
    });
    
  }

  componentDidMount() {
    this.printComponent = React.createRef();
    const params = new URLSearchParams();
    params.append('user_id', webConst.getUserId());
    if (this.props.match && this.props.match.params && this.props.match.params.client_id)
      params.append('client_id', this.props.match.params.client_id);
    params.append('token', webConst.getUserToken());
    this.props.getGoalListRequest(params);
  }

  componentDidUpdate(prevProps) {
    const { goalSuccess, goalFail, goalStatus } = this.props;
    if (goalStatus === 200) {

      if (prevProps.goalSuccess !== goalSuccess) {
        console.log("update", this.state.isLoading);
        if (this.state.isLoading) {
          this.setState({
            isLoading:false
          });
        }

        if (goalSuccess.responseMessage) {
          webConst.toaster(webConst.TOAST_SUCCESS, goalSuccess.responseMessage);
        }

        this.setState({ 'topics': goalSuccess.topics });
        this.setState({ 'goals': goalSuccess.client_goals });
        this.setState({ 'completedGoals': goalSuccess.completed_goals });
        this.setState({ goalName: '' });
        this.setState({ topicId: 0 });
        this.setState({ 'createGoal': false });
        var _this = this;
        setTimeout(function () {
          _this.state.goals.map((item, key) => {
            item.part_goals.map((partItem, partKey) => {
              _this.setState({ goalTitle: { ..._this.state.goalTitle, [partItem.id]: partItem.name } });
              return null;
            });
            _this.setState({ goalTitle: { ..._this.state.goalTitle, [item.id]: item.name } });
            return null;
          });
        }, 100);
      }
    }

    if (prevProps.goalFail !== goalFail && this.state.isLoading) {
      this.setState({
        isLoading: false,
      });
      
      if(goalStatus===400){
        let message = props => {
          return <Translate id="alert-message.client-permission" />
        }
        webConst.toaster(webConst.TOAST_ERROR, message);
        this.props.history.push('/home');
      }
    }

    if (prevProps.journalSuccess !== this.props.journalSuccess) {

      if (this.state.isLoading) {
        this.setState({
          isLoading: false,
        });
      }

      if (this.props.journalSuccess.responseMessage) {
        webConst.toaster(webConst.TOAST_SUCCESS, this.props.journalSuccess.responseMessage);
      }

      let goal_id = this.state.expanJournalGoalId;

      if (this.props.journalSuccess.length) {
        let journals = this.props.journalSuccess;
        delete journals.responseMessage;
        //update journal
        if (this.state.isPartGoalJournal === true || this.state.isPartGoalJournal === "true") {
          let goals = this.state.goals.map(item => {
            let partGoals = item.part_goals.map(partItem => {
              return partItem.id = goal_id ? { ...partItem, journals } : partItem
            });
            item.part_goals = partGoals;
            return item;
          });
          this.setState({ goals: goals });
        }
        else {
          this.setState({
            goals: this.state.goals.map(el => (el.id === goal_id ? { ...el, journals } : el))
          });
        }
        this.setState({ 'expanJournalGoalId': 0 });
      }
      this.setState({ 'isPartGoalJournal': false });
    }
    if (prevProps.journalFail !== this.props.journalFail) {
      this.setState({
        isLoading: false,
      });
    }
  }

  // event to handle input change
  handleChange = (name, value) => {
    this.setState({ goalTitle: { ...this.state.goalTitle, [name]: value } });
  }

  handleTopic = () => {
    console.log("goal id", this.state.topicId);
    if (this.state.topicId === 0) {
      let message = props => {
        return <Translate id="client-goal.select-goal-topic-message" />
      }
      webConst.toaster(webConst.TOAST_ERROR, message);
    }
    else
      this.setState({ 'createGoal': true });
  }

  handleCompleteGoal = (goal_id, clinic_id, client_id) => {

    confirmAlert({
      title: "Fuldfør mål",
      message: "Er du sikker på at gøre dette.",
      buttons: [
        {
          label: "Ja",
          onClick: () => {
            if (goal_id) {
              this.setState({ 'isLoading': true });
              const params = new URLSearchParams();
              params.append('goal_id', goal_id);
              params.append('clinic_id', clinic_id);
              params.append('client_id', client_id);
              params.append('user_id', webConst.getUserId());
              params.append('token', webConst.getUserToken());
              this.props.completeGoalRequest(params);
              this.setDefaultActiveKey(goal_id, 0);
              if(this.state.isShowAllAccodian===true){
                this.setState((state) => {
                  return {isShowAllAccodian: false }
                });
              }
            }
          }
        },
        {
          label: "Ingen",
          // onClick: () => alert('Click No')
        }
      ],
    });

  }

  handleExplandJournal = (goal_id, clinic_id, client_id, defaultActiveKey) => {
    this.setState({ 'expanJournalGoalId': goal_id });
    this.setState({ 'isLoading': true });
    const params = new URLSearchParams();
    params.append('goal_id', goal_id);
    params.append('clinic_id', clinic_id);
    params.append('client_id', client_id);
    params.append('token', webConst.getUserToken());
    this.setDefaultActiveKey(defaultActiveKey, goal_id);
    this.props.getGoalJournalRequest(params);
  }

  setDefaultActiveKey(defaultActiveKey, defaultChildActiveKey) {
    if (defaultActiveKey !== defaultChildActiveKey) {
      this.setState({ "defaultChildActiveKey": defaultChildActiveKey });
      this.setState({ "defaultActiveKey": defaultActiveKey });
    }
    else
      this.setState({ "defaultActiveKey": defaultActiveKey });
  }

  async handleSubmitjournal(event) {
    event.preventDefault();
    if (event.target.journal.value !== "") {
      const params = new URLSearchParams();
      params.append('goal_id', event.target.goal_id.value);
      params.append('clinic_id', event.target.clinic_id.value);
      params.append('client_id', event.target.client_id.value);
      params.append('user_id', webConst.getUserId());
      params.append('journal', event.target.journal.value);
      params.append('token', webConst.getUserToken());
      this.setState({ 'expanJournalGoalId': event.target.goal_id.value });
      this.setState({ 'isPartGoalJournal': event.target.isPartGoalJournal.value });

      if (event.target.main_goal_id && event.target.main_goal_id.value > 0) {
        this.setDefaultActiveKey(event.target.main_goal_id.value, event.target.goal_id.value);
      }
      else {
        this.setDefaultActiveKey(event.target.goal_id.value, event.target.goal_id.value);
      }

      this.props.addJournalRequest(params);
      event.target.journal.value = "";
      this.setState({ 'isLoading': true });
      if(this.state.isShowAllAccodian===true){
        this.setState((state) => {
          return {isShowAllAccodian: false }
        });
      }
    }
    else {
      let message = props => {
        return <Translate id="client-goal.enter-goal-journal-message" />
      }
      webConst.toaster(webConst.TOAST_ERROR, message);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (event.target.topic_id.value === 0 || event.target.topic_id.value === "") {
      let message = props => {
        return <Translate id="client-goal.select-goal-topic-message" />
      }
      webConst.toaster(webConst.TOAST_ERROR, message);
    }
    else if (event.target.goal_name.value === "") {
      let message = props => {
        return <Translate id="client-goal.enter-goal-name-message" />
      }
      webConst.toaster(webConst.TOAST_ERROR, message);
    }
    else {
      // this.setState({ 'isLoading': true });
      const params = new URLSearchParams();

      params.append('goal_id', event.target.goal_id.value);
      params.append('goal_name', event.target.goal_name.value);
      params.append('clinic_id', event.target.clinic_id.value);
      params.append('client_id', event.target.client_id.value);
      params.append('topic_id', event.target.topic_id.value);
      params.append('user_id', webConst.getUserId());
      params.append('goal_type', event.target.goal_type.value);
      params.append('part_goal_id', event.target.part_goal_id.value);
      params.append('token', webConst.getUserToken());
      event.target.goal_name.value = '';
      this.props.saveGoalRequest(params);
      this.setDefaultActiveKey(event.target.goal_id.value, event.target.part_goal_id.value);
      if(this.state.isShowAllAccodian===true){
         this.setState((state) => {
            return {isShowAllAccodian: false }
          });
      }
    }
  }

  createMainGoalJournal(item) {
    return item.journals.map((journalItem, journalKey) => {
      return <li className="list-group-item" key={journalKey}>
        <div dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-user-md"></span> <strong>{journalItem.username}</strong> , {journalItem.clinic}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-hourglass-half"></span> {item.name}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-right"><span className="fa fa-clock-o"></span> {journalItem.journal_datetime}</div>
        </div>
      </li>
    });
  }

  createPartGoalJournal(partItem) {
    return partItem.journals.map((journalItem, journalKey) => {
      return <li className="list-group-item" key={journalKey}>
        <div dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-user-md"></span> <strong>{journalItem.username}</strong> , {journalItem.clinic} </div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-left"><span className="fa fa-hourglass-half"></span> {partItem.name}</div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-right"><span className="fa fa-clock-o"></span> {journalItem.journal_datetime}</div>
        </div>
      </li>
    });
  }

  createPartGoal(item, partItem) {
    let partGoalJournals = this.createPartGoalJournal(partItem);
    return <Card key={partItem.id} className="mt-4 mb-4 part-goal-bg">
      <Card.Header className="">


        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="vg-togglemain">
              <CustomToggle eventKey={partItem.id}><span className="fa fa-angle-double-down"></span></CustomToggle>
            </div>
          </div>
          <div className="col-md-2 col-sm-2"> 
            <Translate id="goal-detail.part-goal-name" />
          </div>
          <div className="col-md-6 col-sm-6">
            {this.state.goalTitle[partItem.id] ? this.state.goalTitle[partItem.id] : ''}
          </div>
        </div>

      </Card.Header>
      <Accordion.Collapse eventKey={partItem.id}>
        <Card.Body className="text-capitalize">
          <div className="row">
            <div className="col-md-8">
              <div className="text-success"><strong><strong><Translate id="client-goal.topic-name" /> : </strong> </strong>{partItem.topic_name}</div>
              <div className="text-primary"><strong><strong><Translate id="client-goal.goal-name" /> : </strong> </strong>{partItem.name}</div>
            </div>
            <div className="col-md-2 text-right">
              <button type="button" className="btn btn-outline-success btn-sm" onClick={e => { this.handleExplandJournal(partItem.id, partItem.clinic_id, partItem.client_id, item.id); this.setState({ isPartGoalJournal: true }) }}><Translate id="client-goal.expand-journal" /></button>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-8 col-md-offset-4">
              <form className="text-left well" onSubmit={this.handleSubmitjournal}>
                <input type="hidden" name="goal_id" className="form-control" value={partItem.id} />
                <input type="hidden" name="main_goal_id" className="form-control" value={item.id} />
                <input type="hidden" name="client_id" className="form-control" value={partItem.client_id} />
                <input type="hidden" name="clinic_id" className="form-control" value={partItem.clinic_id} />
                <textarea type="text" name="journal" className="form-control" onChange={journal => this.setState({ 'journal': journal.target.value })} placeholder="Enter goal journal"></textarea>
                <input type="hidden" name="isPartGoalJournal" className="form-control" value={true} />
                <button className="btn btn-sm btn-outline-success mt-2"><Translate id="client-goal.save-journal" /></button>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {partItem.journals.length ?
                <div className="card mt-4 card-journal">
                  <div className="card-header vg-span-bg-header">
                    <div className="vg-card-title">
                      <Translate id="client-goal.journals" /></div>
                  </div>
                  <div className="card-body fs-12">
                    <ul className="list-group">{partGoalJournals}</ul>
                  </div>
                </div>
                : ''
              }
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  }

  createGoal = function (item) {
    let goalJournals = this.createMainGoalJournal(item);

    let partGoal = [];
    partGoal = item.part_goals.map((partItem, partKey) => {
      // start part goal card
      return this.createPartGoal(item, partItem);
      //end part goal card
    });

    // start main goal card
    return <Card key={item.id} className="m-3">
      <Card.Header>
        <div className="row">
          <div className="col-md-1 col-sm-1">
            <div className="vg-togglemain">
              <CustomToggle eventKey={item.id}><span className="fa fa-angle-double-down"></span></CustomToggle>
            </div>
          </div>
          <div className="col-md-10 col-sm-11">
            <strong className="text-white text-capitalize">{this.state.goalTitle[item.id] ? this.state.goalTitle[item.id] : ''}</strong>
            <form className="row d-none" onSubmit={this.handleSubmit}>
              <div className="col-md-6 col-sm-6">
                <input type="hidden" name="goal_id" className="form-control" value={item.id} />
                <input type="hidden" name="topic_id" className="form-control" value={item.topic_id} />
                <input type="hidden" name="client_id" className="form-control" value={item.client_id} />
                <input type="hidden" name="clinic_id" className="form-control" value={item.clinic_id} />
                <input type="hidden" name="goal_type" className="form-control" value="main-goal" />
                <input type="hidden" name="part_goal_id" className="form-control" value="" />
                <Translate>
                  {({ translate }) => <input type="text" name="goal_name" className="form-control" placeholder={translate('client-goal.goal-name')} onChange={e => this.handleChange(item.id, e.target.value)} value={this.state.goalTitle[item.id] ? this.state.goalTitle[item.id] : ''} />}
                </Translate>
              </div>
              <div className="col-md-6 col-sm-3">
                <button type="submit" className="btn btn-outline-success btn-sm mr-2 vg-btn-tile">
                  <Translate id="Save" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={item.id}>
        <Card.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="text-success"><strong><Translate id="client-goal.topic-name" /> : </strong>{item.topic_name}</div>
              <div className="text-primary"><strong><Translate id="client-goal.goal-name" /> : </strong>{item.name}</div>
            </div>
            <div className="col-md-6">
              <button type="button" className="btn btn-outline-success btn-sm" onClick={e => this.handleExplandJournal(item.id, item.clinic_id, item.client_id, item.id)}><Translate id="client-goal.expand-journal" /></button>
            </div>

            <div className="col-md-6 mt-2">
              <form className="text-left well" onSubmit={this.handleSubmitjournal}>
                <input type="hidden" name="goal_id" className="form-control" value={item.id} />
                <input type="hidden" name="client_id" className="form-control" value={item.client_id} />
                <input type="hidden" name="clinic_id" className="form-control" value={item.clinic_id} />
                <Translate>
                  {({ translate }) => <textarea type="text" name="journal" className="form-control" onChange={journal => this.setState({ 'journal': journal.target.value })} placeholder={translate('client-goal.enter-goal-journal')}></textarea>}
                </Translate>
                <input type="hidden" name="isPartGoalJournal" className="form-control" value={false} />
                <button className="btn btn-sm btn-outline-success mt-2"><Translate id="client-goal.save-journal" /></button>
              </form>
            </div>
            <div className="col-md-6 mt-2">
              <form onSubmit={this.handleSubmit}>
                <input type="hidden" name="goal_id" className="form-control" value={item.id} />
                <input type="hidden" name="topic_id" className="form-control" value={item.topic_id} />
                <input type="hidden" name="client_id" className="form-control" value={item.client_id} />
                <input type="hidden" name="clinic_id" className="form-control" value={item.clinic_id} />
                <input type="hidden" name="goal_type" className="form-control" value="part-goal" />
                <input type="hidden" name="part_goal_id" className="form-control" value="" />
                <Translate>
                  {({ translate }) => <textarea type="text" name="goal_name" className="form-control" placeholder={translate('goal-detail.part-goal-name')} ></textarea>}
                </Translate>
                <button className="btn btn-sm btn-outline-primary mt-2"><Translate id="client-goal.create-part-goal" /></button>
              </form>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {item.journals.length ?
                <div className="card mt-4 card-journal">
                  <div className="card-header vg-span-bg-header vg-part-goal-bg-header">
                    <div className="vg-card-title">
                      <Translate id="client-goal.journals" /></div>
                  </div>
                  <div className="card-body fs-12">
                    <ul className="list-group">{goalJournals}</ul>
                  </div>
                </div>
                : ''
              }

              {/* start part gaol group */}
              {item.part_goals ?
                <Accordion defaultActiveKey={this.state.defaultChildActiveKey}>
                  {partGoal}
                </Accordion>
                : ''
              }
              {/* end part goal group */}
              <div className="text-right mt-2">
                <button className="btn btn-sm btn-danger" onClick={e => this.handleCompleteGoal(item.id, item.clinic_id, item.client_id)}><Translate id="client-goal.complete-goal" /></button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  }

  handleShowAllJournal(){
    const getCollapsedAccordionSections = document.getElementsByClassName(
      "collapse"
    )
    for (var i = 0; i < getCollapsedAccordionSections.length; i++) {
      if(this.state.isShowAllAccodian)
        getCollapsedAccordionSections[i].classList.remove("show");
      else
        getCollapsedAccordionSections[i].classList.add("show");      
        
    }
    this.setState((state) => {
      return {isShowAllAccodian: !state.isShowAllAccodian }
    });
  }
  
  handlePrint(){
    let printContent=this.printComponent.current;
    const content = printContent;
    printJS({
      printable: content.innerHTML,
      type: 'raw-html',
      header: 'Validi Goal',
      footer: 'Validi clinic managment system',
      style: '.blueText {color:blue;}',
      css: '//cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css',
    });
  }
  
  render() {
    const { goalSuccess } = this.props;
    let opticOption = [];
    let clientGoals = [];
    let completedGoals = [];
    let clientName=""
    
    if (goalSuccess) {
      clientName=goalSuccess.client_name?goalSuccess.client_name:"";
      if (this.state.topics.length) {
        let topics = this.state.topics;
        opticOption = topics.map((item, key) => {
          // if (!goalSuccess.goal_topics_active.includes(item.id))
            return <option key={key} value={item.id}>{item.name}</option>
          // else
            // return null;
        });
      }

      if (this.state.goals && this.state.goals.length) {
        clientGoals = this.state.goals.map((item, key) => {
          return this.createGoal(item);

          // end main goal card
        });
      }

      if (this.state.completedGoals && this.state.completedGoals.length) {
        completedGoals = this.state.completedGoals.map((item, key) => {
          return <tr key={key}>
            <td>{key + 1}</td>
            <td className="text-capitalize">{item.topic_name}</td>
            <td className="text-capitalize">
              <Link to={routes.GOAL_DETAIL_ROUTE + '/' + item.id}>{item.name}</Link>
            </td>
            <td>{item.created_at}</td>
          </tr>
        });
      }
    }
    // console.log("client");
    return (
      <div className="vg-card-header">
        <div className="card">
          <div className="card-header vg-main-header">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div className="vg-card-title">
                  <Translate id="client-goal.title" />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div className="text-right">
                    <button type="button" className="btn btn-primary btn-sm vg-btn-tile mr-2" onClick={this.handleShowAllJournal}>
                      {!this.state.isShowAllAccodian ? <Translate id="show-all-journal" />:<Translate id="hide-all-journal" />}
                    </button>
                  <Link className="btn btn-primary btn-sm vg-btn-tile" to={'/'}><Translate id="back" /></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-md-6 pt-2">
              <div ref={this.printComponent} className="d-none">
                <strong><Translate id="client-goal.client-name"></Translate> : <span>{clientName}</span></strong>
                <PrintGoal 
                  goals={this.state.goals}
                  goalTitle={this.state.goalTitle}
                />
              </div>
              <Translate>
                {({ translate }) => <select className="form-control" name="topicId" onChange={topicId => this.setState({ "topicId": topicId.target.value })} ><option value="">{translate("client-goal.select-topic")}</option>{opticOption}</select>}
              </Translate>
            </div>
            <div className="col-md-6 pt-2">
              <strong><Translate id="client-goal.client-name"></Translate> : <span>{clientName}</span></strong> &nbsp;
              <button type="button" onClick={this.handleTopic} className="btn btn-outline-primary">
                <Translate id="client-goal.create-goal" />
              </button>
              <button type="button" onClick={(e)=>this.handlePrint(e)} className="btn btn-outline-primary float-right">
                <Translate id="client-goal.print" />
              </button>
            </div>
          </div>

          <hr />
          {this.state.createGoal ?
            <div>
              <div className="row m-2">
                <div className="col-md-8">
                  <form className="row" onSubmit={this.handleSubmit}>
                    <div className="col-md-6 mt-2">
                      <input type="hidden" name="goal_id" className="form-control" value="" />
                      <input type="hidden" name="topic_id" className="form-control" value={this.state.topicId} />
                      <input type="hidden" name="client_id" className="form-control" value={this.state.clientId} />
                      <input type="hidden" name="clinic_id" className="form-control" value="" />
                      <input type="hidden" name="goal_type" className="form-control" value="main-goal" />
                      <input type="hidden" name="part_goal_id" className="form-control" value="" />
                      <Translate>
                        {({ translate }) => <input type="text" name="goal_name" className="form-control" onChange={goalName => this.setState({ "goalName": goalName.target.value })} value={this.state.goalName} placeholder={translate('client-goal.goal-name')} />}
                      </Translate>
                    </div>
                    <div className="col-md-4 mt-2">
                      <button type="submit" className="btn btn-outline-success btn-sm mr-2"><Translate id="Save" /></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            : ""}
          {this.state.isLoading ?
            <div className="loading"></div> :
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultActiveKey={this.state.defaultActiveKey}>{clientGoals}</Accordion>
              </div>
              <div className="col-md-12">
                {this.state.completedGoals && this.state.completedGoals.length ?
                  <div className="card ml-3 mr-3 mt-2 mb-3">
                    <div className="card-header vg-main-header">
                      <div className="vg-card-title">
                        <Translate id="client-goal.complete-goal" /></div>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead><tr><th>#</th><th><Translate id="client-goal.topic-name" /></th><th><Translate id="client-goal.goal-name" /></th><th><Translate id="client-goal.date" /></th></tr></thead>
                          <tbody>{completedGoals}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  : ''
                }
              </div>
              {this.state.goals && this.state.goals.length === 0 && this.state.completedGoals && this.state.completedGoals.length === 0 ?
                <div className="col-md-12 p-4">
                  <div className="alert alert-danger"><Translate id="client-goal.no-goal-available-message" /></div>
                </div>
                : ''
              }
            </div>

          }
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    goalSuccess: state.goal.success,
    goalFail: state.goal.fail,
    goalStatus: state.goal.status,
    journalSuccess: state.goal.journalSuccess,
    journalFail: state.goal.journalFail,
    journalStatus: state.goal.journalStatus,
  };
}

const mapDispatchToProps = {
  getGoalListRequest,
  saveGoalRequest,
  completeGoalRequest,
  getGoalJournalRequest,
  addJournalRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
