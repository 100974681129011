import { AUTH_STATUS } from './constants';

const initialState = {
  loggedIn: false
};

export default function appLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_STATUS:
      return Object.assign({}, state, {
        loggedIn: action.loggedIn
      });
    default:
      return state;
  }
}