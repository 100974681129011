import React, { Component } from 'react';
import './AppLayout.scss';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import { getAuthStatus } from '../../containers/AppLayoutContainer/action';


class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: props.getAuthStatus(),
    };
  }
  componentDidMount() {

  }

  componentDidUpdate = prevProps => {

    const { loggedIn } = this.props;

    if (loggedIn) {
      if (prevProps.loggedIn !== this.props.loggedIn) {
        this.setState({ 'loggedIn': this.props.loggedIn });
      }
    }
  }

  render() {
    return (
      <div className="app-layout">
        <Header isLoggedIn={this.state.loggedIn} />
        <Footer/>
      </div>
    );
  }
}



function mapStateToProps(state) {
  return {
    loggedIn: state.appLayout.loggedIn
  };
}

const mapDispatchToProps = { getAuthStatus };

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);