import { withoutHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAIL } from './constants';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withoutHeader.baseURL;
axiosDefaults.headers = withoutHeader.headers;
axiosDefaults.timeout = withoutHeader.timeOut;

/*
    API REQUEST 
 */
export const loginRequest = (value) => {
    return async dispatch =>
        axios.post(`${webConst.LOGIN}`, value)
            .then((response) => {
                if(response.data.responseCode===200)
                {
                    dispatch(loginSuccessResult(response.data.responseBody,200,true));
                }
                else{
                    dispatch(loginFailResult(response.data.responseMessage,response.data.responseCode,false));
                }
                
            })
            .catch((error) => {
                console.log(error);
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                // dispatch(loginFailResult(error.response,error.response.status));
            });
};


/*
    API RESULT
 */

export const loginSuccessResult = (success,status,loggedIn) => ({
    type: LOGIN_SUCCESS,
    success,
    status,
    loggedIn
});

export const loginFailResult = (error,status,loggedIn) => ({
    type: LOGIN_FAIL,
    error,
    status,
    loggedIn
});
