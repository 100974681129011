import { GOAL_SUCCESS, GOAL_FAIL, JOURNAL_SUCCESS, JOURNAL_FAIL} from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getGoalListRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_GOALS}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(clientSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(clientFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(clientFailResult(error.data, error.status));
            });
    }
};

export const saveGoalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.CREATE_GOAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    response.data.responseBody.responseMessage = response.data.responseMessage;
                    dispatch(clientSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(clientFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(clientFailResult(error.data, error.status));
            });
    }
};

export const completeGoalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.COMPLETE_GOAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    response.data.responseBody.responseMessage = response.data.responseMessage;
                    dispatch(clientSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(clientFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(clientFailResult(error.data, error.status));
            });
    }
}


export const getGoalJournalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_GOAL_JOURNAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(journalSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(journalFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(journalFailResult(error.data, error.status));
            });
    }
}

export const addJournalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_GOAL_JOURNAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    response.data.responseBody.responseMessage = response.data.responseMessage;
                    dispatch(journalSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(journalFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(journalFailResult(error.data, error.status));
            });
    }
}

/*
    API RESULT
 */

export const clientSuccessResult = (success, status) => ({
    type: GOAL_SUCCESS,
    success,
    status
});

export const clientFailResult = (error, status) => ({
    type: GOAL_FAIL,
    error,
    status
});

export const journalSuccessResult = (success, status) => ({
    type: JOURNAL_SUCCESS,
    success,
    status
});

export const journalFailResult = (error, status) => ({
    type: JOURNAL_FAIL,
    error,
    status
});

